(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/product-types/pix-product-type-number-board.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/product-types/pix-product-type-number-board.js');

'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  PixProductBase
} = svs.components.sport.pixHandler.productTypes;
const {
  FLAVOUR_TYPES,
  SYSTEM_TYPES
} = svs.components.sport.pixHandler.common.constants;
const ROW_PRICE_MALTIPS = 2.5;
const NUMBER_OF_MATCH_EVENTS_MALTIPS = 30;
const MAP_MALTIPS_SIZE_TO_MSYS = {
  9: 9,
  45: 10,
  165: 11 
};
const _convertMaltipsetBoardToSigns = (board, numberOfEvents) => {
  const signs = new Array(numberOfEvents).fill(false);
  board.forEach(eventNumber => {
    signs[eventNumber.value - 1] = true;
  });
  return signs;
};
var _flavour = new WeakMap();
class PixProductTypeNumberBoard extends PixProductBase {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _flavour, FLAVOUR_TYPES.SAFE_MALTIPSET);
  }
  get flavour() {
    return _classPrivateFieldGet(_flavour, this);
  }
  getURL() {
    const numberOfRows = this.getNumberOfRows(ROW_PRICE_MALTIPS);
    let url = "/wager/1/maltipset/pix?flavour=".concat(_classPrivateFieldGet(_flavour, this), "&systemNumberMapping=").concat(MAP_MALTIPS_SIZE_TO_MSYS[numberOfRows], "&drawNumber=").concat(this.drawNumber);
    if (this.systemType === SYSTEM_TYPES.SROW) {
      url = "/wager/1/maltipset/pix/singlerows?flavour=SafeMaltipset&drawNumber=".concat(this.drawNumber, "&numRows=").concat(this.systemCost);
    }
    return url;
  }
  signs() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const pix = data.length ? data : [data];
    if (!Array.isArray(pix) || pix.length === 0) {
      throw new Error('Missing "pix.events" in response from wager-API.');
    }
    return pix.map(board => _convertMaltipsetBoardToSigns(board.outcomes, NUMBER_OF_MATCH_EVENTS_MALTIPS));
  }
  board() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const pix = data.length ? data : [data];
    if (!Array.isArray(pix) || pix.length === 0) {
      throw new Error('Missing "pix.events" in response from wager-API.');
    }
    return {
      boardData: pix.map(board => board.outcomes.map(_ref => {
        let {
          value
        } = _ref;
        return value;
      }))
    };
  }
}
setGlobal('svs.components.sport.pixHandler.productTypes.PixProductTypeNumberBoard', PixProductTypeNumberBoard);

 })(window);