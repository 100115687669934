(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/pix/create-pix-handler.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/pix/create-pix-handler.js');

'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const logger = new svs.core.log.Logger('svs.components.sport.pixHandler.pix.createPixHandler');
const {
  PixProductType1x2,
  PixProductTypeNumberBoard
} = svs.components.sport.pixHandler.productTypes;
const {
  PixProductBomben,
  PixProductFulltraff,
  PixProductRacing,
  PixProductChallenge,
  PixProductMatchen
} = svs.components.sport.pixHandler.productTypes.products;
const {
  PixData
} = svs.components.sport.pixHandler.pix;
const {
  createPixDataKey
} = svs.components.sport.pixHandler.common.helpers;
const {
  createWager
} = svs.components.payment.createWager;
const {
  oneXTwoToBinary
} = svs.components.payment.createWager.utils;
const {
  WEIGHTEDQP,
  XPERTEN
} = svs.components.sport.pixHandler.common.constants.QUICKPICK_PROPS;
const {
  PIXMIX
} = svs.components.sport.pixHandler.common.constants.ADDITIONAL_CACHE_KEYS;
const {
  KEEP_OPEN_CACHE_KEY
} = svs.components.sport.pixHandler.common.constants;
const {
  splitBombenExpertenBoardDataToRows
} = svs.components.sport.expandedSystems;
const {
  cache
} = svs.components.sport.pixHandler.common;
const {
  BOMBEN,
  MALTIPSET,
  FULLTRAFF,
  T8,
  T8EXTRA,
  T8STRYK,
  EUROPATIPSET,
  STRYKTIPSET,
  CHALLENGE,
  POWERPLAY,
  MATCHEN,
  RACING
} = svs.utils.products.productIds;
var _cache = new WeakMap();
var _products = new WeakMap();
var _cachedPixData = new WeakMap();
var _isPixMix = new WeakMap();
class PixHandler {
  constructor(_ref) {
    let {
      isPixMix
    } = _ref;
    _classPrivateFieldInitSpec(this, _cache, cache);
    _classPrivateFieldInitSpec(this, _products, new Map());
    _classPrivateFieldInitSpec(this, _cachedPixData, new Map());
    _classPrivateFieldInitSpec(this, _isPixMix, false);
    _defineProperty(this, "fetch", async _ref2 => {
      let {
        productId,
        shouldGenerateNewPix
      } = _ref2;
      const {
        drawNumber,
        systemCost,
        systemType
      } = _classPrivateFieldGet(_products, this).get(productId);
      const url = _classPrivateFieldGet(_products, this).get(productId).getURL();
      const additionalCacheKey = _classPrivateFieldGet(_isPixMix, this) ? PIXMIX : '';
      const pixDataKey = createPixDataKey(productId, drawNumber, systemCost, systemType, additionalCacheKey);
      let pixData = _classPrivateFieldGet(_cachedPixData, this).get(pixDataKey);
      if (!pixData || shouldGenerateNewPix) {
        pixData = await new PixData({
          productId,
          drawNumber,
          systemCost,
          systemType,
          additionalCacheKey,
          shouldGenerateNewPix,
          url
        });
        _classPrivateFieldGet(_cachedPixData, this).set(pixDataKey, pixData.cachedPixData);
      }
      return _classPrivateFieldGet(_cachedPixData, this);
    });
    _classPrivateFieldSet(_isPixMix, this, isPixMix);
  }
  get isPixMix() {
    return _classPrivateFieldGet(_isPixMix, this);
  }
  addProduct(productId, callback) {
    let pixProduct;
    switch (productId) {
      case T8:
      case T8STRYK:
      case T8EXTRA:
      case POWERPLAY:
      case EUROPATIPSET:
      case STRYKTIPSET:
        pixProduct = new PixProductType1x2(productId);
        break;
      case MALTIPSET:
        pixProduct = new PixProductTypeNumberBoard(productId);
        break;
      case BOMBEN:
        pixProduct = new PixProductBomben(productId);
        break;
      case MATCHEN:
        pixProduct = new PixProductMatchen(productId);
        break;
      case FULLTRAFF:
        pixProduct = new PixProductFulltraff(productId);
        break;
      case RACING:
        pixProduct = new PixProductRacing(productId);
        break;
      case CHALLENGE:
        pixProduct = new PixProductChallenge(productId);
        break;
      default:
        throw new Error("Support for productId: ".concat(productId, " not implemented yet"));
    }
    _classPrivateFieldGet(_products, this).set(productId, pixProduct);
    callback(pixProduct);
    return this;
  }
  getProduct(productId) {
    return _classPrivateFieldGet(_products, this).get(productId);
  }
  createPixWager(_ref3) {
    let {
      productId,
      subProductId,
      bagType = 0
    } = _ref3;
    const {
      rowPrice,
      drawNumber
    } = this.getProduct(productId);
    const {
      boardData
    } = this.compose(productId);
    const wager = createWager().addBet(productId, bet => {
      bet.setDrawNumber(drawNumber);
      switch (productId) {
        case FULLTRAFF:
          {
            bet.setRowPrice(rowPrice).addBoard(board => {
              boardData.forEach(row => board.addOutcomes(row.map(value => parseInt(value, 10))).addBoardProperty(WEIGHTEDQP));
            });
            break;
          }
        case EUROPATIPSET:
        case STRYKTIPSET:
        case POWERPLAY:
        case CHALLENGE:
        case T8:
        case T8EXTRA:
        case T8STRYK:
          {
            bet.setRowPrice(rowPrice).addBoard(board => {
              boardData.forEach(row => board.addOutcomes(oneXTwoToBinary(row)).addBoardProperty(WEIGHTEDQP));
            });
            if (subProductId) {
              bet.setSubProductId(subProductId);
            }
            break;
          }
        case MALTIPSET:
          {
            bet.setRowPrice(rowPrice);
            boardData.forEach(row => {
              bet.addBoard(board => board.addOutcomes(row).addBoardProperty(WEIGHTEDQP));
            });
            break;
          }
        case RACING:
          {
            const {
              systemType
            } = this.getProduct(productId);
            bet.setRowPriceMulti(boardData.rowPriceMulti).setSystemType(boardData.rxBoards[0].systemType).setSubProductId(systemType).addBoard(board => board.addOutcomes(boardData.rxBoards).setRowCount(boardData.numRows));
            break;
          }
        case BOMBEN:
          {
            bet.addBetProperty(XPERTEN);
            const rows = splitBombenExpertenBoardDataToRows(boardData);
            rows.forEach(row => {
              const singleRow = [];
              while (row.length > 0) {
                const eventSigns = row.splice(0, 2);
                singleRow.push(eventSigns);
              }
              if (row.length === 0) {
                bet.addBoard(board => {
                  singleRow.forEach(eventSigns => board.addOutcomes([eventSigns[0]], [eventSigns[1]]));
                });
              }
            });
            break;
          }
        case MATCHEN:
          {
            bet.addBetProperty(XPERTEN).setRowPrice(rowPrice);
            const rows = splitBombenExpertenBoardDataToRows(boardData);
            rows.forEach(row => {
              const singleRow = [];
              while (row.length > 0) {
                const eventSigns = row.splice(0, 2);
                singleRow.push(eventSigns);
              }
              if (row.length === 0) {
                bet.addBoard(board => {
                  singleRow.forEach(eventSigns => board.addOutcomes([eventSigns[0]], [eventSigns[1]]));
                });
              }
            });
            break;
          }
        default:
          {
            logger.info("unsupported productId(".concat(productId, ") when creating wager"));
          }
      }
    }).setBagType(bagType);
    return wager;
  }
  get keepOpenItem() {
    return _classPrivateFieldGet(_cache, this).getData({
      itemKey: KEEP_OPEN_CACHE_KEY
    });
  }
  setKeepOpenItem(keepOpenItem) {
    _classPrivateFieldGet(_cache, this).setData({
      itemKey: KEEP_OPEN_CACHE_KEY,
      data: keepOpenItem
    });
  }
  unsetKeepOpenItem() {
    _classPrivateFieldGet(_cache, this).unsetData({
      itemKey: KEEP_OPEN_CACHE_KEY
    });
  }
  getModel(productId) {
    const {
      drawNumber,
      systemCost,
      systemType
    } = _classPrivateFieldGet(_products, this).get(productId) || {};
    const additionalCacheKey = _classPrivateFieldGet(_isPixMix, this) ? PIXMIX : '';
    const pixDataKey = createPixDataKey(productId, drawNumber, systemCost, systemType, additionalCacheKey);
    const pixData = _classPrivateFieldGet(_cachedPixData, this).get(pixDataKey);
    if (!pixData) {
      return;
    }
    return _classPrivateFieldGet(_products, this).get(productId).signs(pixData);
  }
  compose(productId) {
    const {
      drawNumber,
      systemCost,
      systemType
    } = _classPrivateFieldGet(_products, this).get(productId) || {};
    const additionalCacheKey = _classPrivateFieldGet(_isPixMix, this) ? PIXMIX : '';
    const pixDataKey = createPixDataKey(productId, drawNumber, systemCost, systemType, additionalCacheKey);
    const pixData = _classPrivateFieldGet(_cachedPixData, this).get(pixDataKey);
    if (!pixData) {
      return;
    }
    return _classPrivateFieldGet(_products, this).get(productId).board(pixData);
  }
}
const createPixHandler = _ref4 => {
  let {
    isPixMix
  } = _ref4;
  return new PixHandler({
    isPixMix
  });
};
setGlobal('svs.components.sport.pixHandler.pix.createPixHandler', createPixHandler);

 })(window);