(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/product-types/pix-product-type-1x2.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/product-types/pix-product-type-1x2.js');

'use strict';

const {
  PixProductBase
} = svs.components.sport.pixHandler.productTypes;
const {
  getSpecificDrawName
} = svs.utils.products;
const _convertTipsBoardToSigns = board => {
  const signs = [];
  board.forEach(_ref => {
    let {
      outcomes
    } = _ref;
    const newOutcomes = [];
    newOutcomes.push(Boolean(outcomes.find(_ref2 => {
      let {
        value
      } = _ref2;
      return value === '1';
    })));
    newOutcomes.push(Boolean(outcomes.find(_ref3 => {
      let {
        value
      } = _ref3;
      return value === 'X';
    })));
    newOutcomes.push(Boolean(outcomes.find(_ref4 => {
      let {
        value
      } = _ref4;
      return value === '2';
    })));
    signs.push(newOutcomes);
  });
  return signs;
};
const _convertTipsBoardToWager = board => {
  const newBoard = [];
  board.forEach(_ref5 => {
    let {
      outcomes
    } = _ref5;
    const newOutcomes = [];
    if (outcomes.find(_ref6 => {
      let {
        value
      } = _ref6;
      return value === '1';
    })) {
      newOutcomes.push('1');
    }
    if (outcomes.find(_ref7 => {
      let {
        value
      } = _ref7;
      return value === 'X';
    })) {
      newOutcomes.push('X');
    }
    if (outcomes.find(_ref8 => {
      let {
        value
      } = _ref8;
      return value === '2';
    })) {
      newOutcomes.push('2');
    }
    newBoard.push(newOutcomes.join(''));
  });
  return newBoard;
};
class PixProductType1x2 extends PixProductBase {
  getURL() {
    return "/wager/1/".concat(getSpecificDrawName(this.productId), "/pix?flavour=Safe1X2&systemType=").concat(this.systemType, "&systemNumberMapping=").concat(this.systemCost, "&drawNumber=").concat(this.drawNumber);
  }
  signs() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const {
      outcomes
    } = data[0] || {};
    if (!Array.isArray(outcomes)) {
      throw new Error('Missing "pix.outcomes" in response from wager-API (1x2)');
    }
    return _convertTipsBoardToSigns(data);
  }
  board() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const {
      outcomes
    } = data[0] || {};
    if (!Array.isArray(outcomes)) {
      throw new Error('Missing "pix.outcomes" in response from wager-API (1x2)');
    }
    return {
      boardData: _convertTipsBoardToWager(data)
    };
  }
}
setGlobal('svs.components.sport.pixHandler.productTypes.PixProductType1x2', PixProductType1x2);

 })(window);